.homepage_image {
  background-image: url("../../public/assets/homepage/homepage_main.jpg");
  background-position: center;
  background-size: cover;
  height: 100vh;
  display: flex;
  align-items: flex-end;
  margin-bottom: 0px;
  padding: 0px;
}

.homepage_image p {
  color: white;
  margin: 0px 50px 50px 50px;
  text-align: center;
  font-size: 1.3rem;
}

.overlay {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
  overflow: hidden;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 2;
}
