.editbutton {
  position: absolute;
  margin-top: 10px;
  margin-left: 10px;
}

.deletebutton {
  position: absolute;
  margin-top: 10px;
  margin-left: 10px;
}

.swapbutton {
  position: absolute;
  margin-top: 10px;
  margin-left: 70px;
}

.post_image {
  min-height: 100%;
  width: 50%;
  background-size: cover;
  background-position: center;
}
